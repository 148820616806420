<template>
  <ion-page>
    <ion-content color="primary" :fullscreen="true">

      <div class="auth-content">
        <div class="auth">
            <ion-img class="logo" src="https://cms.app.thb.torben.wtf/assets/9e9543b8-95f8-44a7-87ba-7199db322dff"></ion-img>
            <h2>{{$t('auth.login')}}</h2>
            <p>{{$t('auth.login_text')}}</p>

            <form class="auth-form">
                <ion-input class="auth-input" type="text" name="username" v-model="email" :placeholder="$t('auth.email')" />
                <ion-input class="auth-input" type="password" name="password" v-model="password" :placeholder="$t('auth.password')" />
                <ion-button color="light" type="button" v-on:click="login()">{{ $t('auth.login') }}</ion-button>
            </form>

            <p v-if="error">{{ $t('auth.error') }}</p>

            <ion-button class="tutorial-button" color="dark" @click="toTutorial()">
                {{ $t('auth.tutorial') }}
            </ion-button>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="js">
import { IonPage, IonToolbar, IonTitle, IonContent, IonLabel, IonInput, IonButton, IonImg } from '@ionic/vue';
import {app, directus} from '../main'

export default {
  name: 'Login',
  components: { IonToolbar, IonTitle, IonPage, IonContent, IonLabel, IonInput, IonButton, IonImg },
  data () {
    return {
      email: null,
      password: null,
      error: null,
    }
  },
  methods: {
    login () {
      directus.auth.login({ email: this.email, password: this.password })
        .then(res => {
            app.config.globalProperties.loggedIn = true;
            this.$router.push({name:'news'})
          })
        .catch(err => {
          this.error = true ;
          this.email = null;
          this.password = null;
          console.log(err);
          });
    },
    toTutorial () {
        this.$router.push({name:'tutorial'})
    }
  }
}
</script>

<style scoped>
.auth {
  height: 100%;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.auth-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.auth-input {
  --background: #333;
  margin: 10px 0;
  max-width: 400px;
}
.logo {
  width: 50%;
  max-width: 250px;
}
.tutorial-button {
  margin-top: 20px;
}
</style>
